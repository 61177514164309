import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import moment from 'moment';
import Pagination from "react-reactstrap-pagination";

import { toast } from 'react-toastify';

import AthleteDetailsModal from '../../TrainingPlan/AthleteDetailModal';

import UserNewModal from '../create';
import UserShowModal from '../show';
import SignatureModal from '../signature';

import api from '~/services/api';

import Loader from '~/components/Loader/Loader';

export default class User extends Component {
    constructor(props) {
        super(props);

        this.loadUsers = this.loadUsers.bind(this);

        this.state = {
            users: [],
            loading: true,
            showNewUserModal: false,
            showSignatureModal: false,
            showUserActivitiesModal: false,
            userToEdit: null,
            userToShow: null,
            searchQuery: '',
            selectedPage: 1,
            totalItems: 0,
            perPage: 20,
            lastPage: 1
        }

        this.loadUsers();
    }

    loadUsers = async (selectedPage) => {
        const { data } = await api.get(`users?searchQuery=${this.state.searchQuery}&page=${selectedPage}&limit=${this.state.perPage}`);

        this.setState({ ...this.state, users: data.data, loading: false, totalItems: data.total, perPage: data.perPage, lastPage: data.lastPage, selectedPage: selectedPage });
    }

    handleNewUserModal = async (userToEdit) => {

        userToEdit = (userToEdit && userToEdit.id > 0) ? userToEdit : null;

        this.setState({ userToEdit, showNewUserModal: !this.state.showNewUserModal });
    }

    handleSignatureModal = async (user) => {
        if (user && user.subscriptions) {
            this.setState({ signatureToShow: user.subscriptions[0], userToShow: user, showSignatureModal: !this.state.showSignatureModal });
        } else if (!user) {
            this.setState({ signatureToShow: null, userToShow: null, showSignatureModal: !this.state.showSignatureModal });
        }
    }

    handleUpdateSubscriptionModal = async (user) => {
        if (user && user.subscriptions) {

            var result = window.confirm("Tem certeza que deseja atuaizar essa assinatura la na Vindi?");

            if (result) {
                const resp = await api.put(`subscriptions/${user.subscriptions[0].id}`, {})

                if (resp && resp.status === 200) {
                    toast.success('Assinatura atualiada com sucesso na Vindi')
                } else {
                    toast.error('Erro ao atualizar assinatura na Vindi')
                }
            }
        }
    }

    handleSetSearchQuery = (search) => {
        this.setState({ searchQuery: search })
    }

    handleUserDetailModal = async (userToShow) => {
        this.setState({ userToShow, showUserActivitiesModal: !this.state.showUserActivitiesModal });
    }

    handleSimulateUserLogin = async (userToSimulate) => {
        // const data = {
        //     email: userToSimulate.email,
        //     email: userToSimulate.email,
        //     email: userToSimulate.email,
        // }

        // const resp = await api.put(`sessions_simulate`, {})

        // if (resp && resp.status === 200) {
        //     toast.success('Assinatura atualiada com sucesso na Vindi')
        // } else {
        //     toast.error('Erro ao atualizar assinatura na Vindi')
        // }
    }

    handleSearchUser = async (event) => {
        if (event) event.preventDefault()

        if (this.state.searchQuery.length === 0) {
            this.loadUsers(1)
            return
        }

        this.setState({ ...this.state, loading: true });

        const { data } = await api.get(`users?searchQuery=${this.state.searchQuery}&page=${1}&limit=${this.state.perPage}`);

        this.setState({ ...this.state, users: data.data, loading: false, totalItems: data.total, perPage: data.perPage, lastPage: data.lastPage, selectedPage: 1 });

    }

    handleEmailRecoveryPass = async (user) => {
        const { data } = await api.post(`/passwords`, {
            email: user.email, redirect_url: `${process.env.REACT_APP_FORGOT_PASSWORD_REDIRECT_URL}`
        });

        if (data.status === 200) {
            toast.success('Email de recuperação de senha enviado com sucesso')
        } else {
            toast.error('Erro ao enviar email de recuperação de senha')
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <Loader />}

                <Row>
                    <Col>
                        <div className="page-title-box">
                            <Row>
                                <Col lg={7}>
                                    <h4 className="page-title">Usuários</h4>
                                </Col>
                                <Col lg={5} className="mt-lg-3 mt-md-0">

                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card style={{ overflow: 'auto' }}>
                            <CardBody>
                                <p>Usuários</p>
                                <form onSubmit={this.handleSearchUser}>
                                    <InputGroup>
                                        <Input placeholder="pesquisar por email" onChange={(e) => this.setState({ searchQuery: e.target.value })} />
                                        <InputGroupAddon addonType="append">
                                            <Button color="success">Pesquisar</Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </form>
                                <Button color="info" style={{ marginTop: 15, marginBottom: 10 }} onClick={this.handleNewUserModal}>Novo Usuário</Button>
                                <>
                                    <table className="table table-centered table-hover mb-0" id="btn-editable">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Perfil</th>
                                                <th>Criação</th>
                                                <th className="tabledit-toolbar-column"></th>
                                                <th className="tabledit-toolbar-column"></th>
                                                <th className="tabledit-toolbar-column"></th>
                                                <th className="tabledit-toolbar-column"></th></tr>

                                        </thead>

                                        <tbody>
                                            {this.state.users.map(user => (
                                                <tr id="1" key={user.id}>
                                                    <td><span className="tabledit-span tabledit-identifier">{user.id}</span></td>
                                                    <td className="tabledit-view-mode"><span className="tabledit-span"><button style={{ marginRight: 10 }} className="btn btn-link" color="primary" onClick={() => {
                                                        this.setState({ ...this.state, userToShow: user, userDetailsModal: true })
                                                    }}>{user.name}</button></span></td>

                                                    <td className="tabledit-view-mode"><span className="tabledit-span">{user.email}</span></td>
                                                    <td className="tabledit-view-mode"><span className="tabledit-span">{user.roles && user.roles[0] ? user.roles[0].name : '--'}</span></td>
                                                    <td className="tabledit-view-mode"><span className="tabledit-span">{moment(user.created_at).format('DD/MM/YYYY')}</span></td>
                                                    <td className="tabledit-view-mode"><Button color="primary" onClick={() => {
                                                        if (user.subscriptions && user.subscriptions.length > 0) {
                                                            this.handleSignatureModal(user)
                                                        } else {
                                                            toast.warn('Usuário sem assinatura registrada')
                                                        }
                                                    }}>Assinatura</Button></td>
                                                    <td className="tabledit-view-mode"><Button color="primary" onClick={() => {
                                                        if (user.subscriptions && user.subscriptions.length > 0) {
                                                            this.handleUpdateSubscriptionModal(user)
                                                        } else {
                                                            toast.warn('Usuário sem assinatura registrada')
                                                        }
                                                    }}>Atualizar Assinatura</Button></td>

                                                    <td className="tabledit-view-mode"><Button color="primary" onClick={() => this.handleNewUserModal(user)}>Editar</Button></td>
                                                    <td className="tabledit-view-mode"><Button color="primary" onClick={() => this.handleUserDetailModal(user)}>Atividades</Button></td>
                                                    <td className="tabledit-view-mode"><Button color="primary" onClick={() => {
                                                        var result = window.confirm("Tem certeza que deseja enviar um email de primeiro acesso pra o usuário: " + user.email);
                                                        if (result) {
                                                            this.handleEmailRecoveryPass(user)
                                                        }
                                                    }}>recuperar senha</Button></td>
                                                    <td className="tabledit-view-mode"><Button color="primary" onClick={() => this.handleSimulateUserLogin(user)}>Simular Login</Button></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} style={{ overflow: 'auto' }}>
                        <Pagination
                            firstPageText="Início"
                            lastPageText="Fim"
                            previousPageText="Anterior"
                            nextPageText="Próximo"
                            totalItems={this.state.totalItems}
                            pageSize={this.state.perPage}
                            maxPaginationNumbers={5}
                            defaultActivePage={1}
                            onSelect={this.loadUsers}
                        />
                    </Col>
                </Row>

                {this.state.showNewUserModal && <UserNewModal open={this.state.showNewUserModal} user={this.state.userToEdit} toggle={this.handleNewUserModal} loadUsers={this.loadUsers} selectedPage={this.state.selectedPage} searchQuery={this.state.searchQuery} handleSetSearchQuery={this.handleSetSearchQuery} handleSearchUser={this.handleSearchUser} />}
                {this.state.showUserActivitiesModal && <UserShowModal open={this.state.showUserActivitiesModal} user={this.state.userToShow} toggle={this.handleUserDetailModal} />}
                {this.state.showSignatureModal && <SignatureModal open={this.state.showSignatureModal} signature={this.state.signatureToShow} user={this.state.userToShow} toggle={this.handleSignatureModal} handleSearchUser={this.handleSearchUser} />}
                {this.state.userDetailsModal && <AthleteDetailsModal open={this.state.userDetailsModal} justShow={true} toggle={() => this.setState({ ...this.state, userDetailsModal: false })} athlete={this.state.userToShow} />}
            </React.Fragment>

        );

    };
}
